:root {
  --font-1: "Souvenir-Light";
  --font-2: "Souvenir-Bold";
  --font-3: "Windsor-Bold";
  --font-4: "Windsor-Extra-Bold";
  --font-5: "Souvenir-Medium-Italic";
  --font-6: "Souvenir-Demi";
}

@font-face {
  font-family: Souvenir-Light;
  src: url("../../clients/#{$client}/fonts/SouvenirLight.otf")
    format("opentype");
}
@font-face {
  font-family: Souvenir-Bold;
  src: url("../../clients/#{$client}/fonts/SouvenirBold.otf") format("opentype");
}
@font-face {
  font-family: Souvenir-Medium-Italic;
  src: url("../../clients/#{$client}/fonts/SouvenirMediumItalic.otf")
    format("opentype");
}
@font-face {
  font-family: Souvenir-Demi;
  src: url("../../clients/#{$client}/fonts/Souvenir-Demi.otf")
    format("opentype");
}
@font-face {
  font-family: Windsor-Bold;
  src: url("../../clients/#{$client}/fonts/WindsorBold.ttf") format("truetype");
}
@font-face {
  font-family: Windsor-Extra-Bold;
  src: url("../../clients/#{$client}/fonts/WindsorExtraBold.ttf")
    format("truetype");
}

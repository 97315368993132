:root {
  --focus-text-color: #FF4337;
  --focus-ring-color: #FFADAD;
}.base {
  
  
  background-color: #FFFFFF;
}
.accentGreenLightRounded {
  
  
  border-radius: 5px;
  background-color: #A8DCA8;
}
.accentGreenRounded {
  
  
  border-radius: 5px;
  background-color: #009B00;
}
.baseBlack {
  
  
  background-color: #FF4337;
}
.alternateGray {
  
  
  background-color: #F2F2F2;
}
.accentLight {
  
  
  background-color: #FFADAD;
}
.accentOrange {
  
  
  border-radius: 5px;
  background-color: #F3EBDF;
}
.accentGreenCircle {
  
  
  border-radius: 50px;
  background-color: #009B00;
}
.accentDark {
  
  
  background-color: #FF4337;
}
.accentDarkCircle {
  
  
  border-radius: 50px;
  background-color: #FF4337;
}
.accentLightLightBordered {
  
  
  border-width: 1px;
  border-style: solid;
  border-color: #FFADAD;
  background-color: #FFFFFF;
}
.accentDarkVeryHeavyBordered {
  
  
  border-width: 5px;
  border-style: solid;
  border-color: #FF4337;
  background-color: #FF4337;
}
.baseBordered {
  
  
  border-width: 1px;
  border-style: solid;
  border-color: #FF4337;
  background-color: #FFFFFF;
}
.baseAccentBordered {
  
  
  border-width: 1px;
  border-style: solid;
  border-color: #FF4337;
  background-color: #FFFFFF;
}
.baseAccentGreen {
  
  
  border-width: 1px;
  border-style: solid;
  border-color: #009B00;
  background-color: #FFFFFF;
}
.accentGreen {
  
  
  border-radius: 5px;
  background-color: #009B00;
}
.accentLightRounded {
  
  
  border-radius: 5px;
  background-color: #FFADAD;
}
.baseAlternateGrayBordered {
  
  
  border-width: 1px;
  border-style: solid;
  border-color: #F2F2F2;
  background-color: #FFFFFF;
}
.baseAccentDarkBorderedRound {
  
  
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: #FF4337;
  background-color: #FFFFFF;
}
.baseAccentGreenBorderedRound {
  
  
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: #009B00;
  background-color: #FFFFFF;
}
.baseAccentDarkBorderedCircle {
  &:active {
    border-color: #009B00;
  }
  
  border-radius: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: #FFFFFF;
  background-color: #FFFFFF;
}
.baseBackgroundGrayBordered {
  &:active {
    border-color: #009B00;
  }
  
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #009B00;
  background-color: #F7F7F7;
}
.baseAccentLightVeryHeavyBordered {
  
  
  border-width: 5px;
  border-style: solid;
  border-color: #FFADAD;
  background-color: #FFFFFF;
}
.baseDarkBordered {
  
  
  border-width: 1px;
  border-style: solid;
  border-color: #FF4337;
  background-color: #FFFFFF;
}
.baseAccentGreenBordered {
  &:active {
    border-color: #009B00;
  }
  
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #FFFFFF;
  background-color: #F7F7F7;
}
.baseAccentGreenBorderedCircle {
  &:active {
    border-color: #009B00;
  }
  
  border-radius: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: #009B00;
  background-color: #FFFFFF;
}
.alternateGrayBordered {
  
  
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: #F2F2F2;
  background-color: #F2F2F2;
}
.baseWhiteAlternateGrayBordered {
  
  
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: #F2F2F2;
  background-color: #FFFFFF;
}
.baseAccentDarkBordered {
  
  
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #FF4337;
  background-color: #FFFFFF;
}
.accentGreenCircleBordered {
  
  
  border-radius: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: #009B00;
  background-color: #FFFFFF;
}
.baseAccentDarkHeavyBordered {
  
  
  border-width: 1px;
  border-style: solid;
  border-color: #FF4337;
  background-color: #FFFFFF;
}
.accentLightCircle {
  
  
  border-radius: 100px;
  background-color: #FFADAD;
}
.accentLightCircleBordered {
  
  
  border-radius: 50px;
  border-width: 2px;
  border-style: solid;
  border-color: #FFADAD;
  background-color: #FFADAD;
}
.accentDarkRounded {
  
  
  border-radius: 5px;
  background-color: #FF4337;
}
.accentLightBordered {
  
  
  border-width: 2px;
  border-style: solid;
  border-color: #FFADAD;
  background-color: #FFFFFF;
}.title1 {font-family: "Windsor-Extra-Bold";
  font-size: 3.5rem;
  font-weight: 400;}
.title2 {font-family: "Windsor-Extra-Bold";
  font-size: 2.125rem;
  font-weight: 400;}
.title3 {font-family: "Windsor-Extra-Bold";
  font-size: 1.75rem;
  font-weight: 400;}
.title4 {font-family: "Souvenir-Demi";
  font-size: 1.125rem;
  font-weight: 400;}
.title5 {font-family: "Souvenir-Light";
  font-size: 1.875rem;
  font-weight: 400;}
.title6 {font-family: "Windsor-Bold";
  font-size: 1.625rem;
  font-weight: 400;}
.title7 {font-family: "Windsor-Bold";
  font-size: 1.5rem;
  font-weight: 400;}
.subtitle1 {font-family: "Windsor-Bold";
  font-size: 1.25rem;
  font-weight: 400;}
.subtitle2 {font-family: "Souvenir-Bold";
  font-size: 0.6875rem;
  font-weight: 400;}
.subtitle3 {font-family: "Windsor-Extra-Bold";
  font-size: 1.5rem;
  font-weight: 400;}
.subtitle4 {font-family: "Souvenir-Bold";
  font-size: 1rem;
  font-weight: 400;}
.subtitle5 {font-family: "Souvenir-Light";
  font-size: 1rem;
  font-weight: 400;}
.subtitle6 {font-family: "Souvenir-Demi";
  font-size: 1rem;
  font-weight: 400;}
.subtitle7 {font-family: "Windsor-Extra-Bold";
  font-size: 1.125rem;
  font-weight: 400;}
.subtitle8 {font-family: "Windsor-Extra-Bold";
  font-size: 1.375rem;
  font-weight: 400;}
.body1 {font-family: "Souvenir-Light";
  font-size: 1.125rem;
  font-weight: 400;}
.body2 {font-family: "Souvenir-Light";
  font-size: 0.875rem;
  font-weight: 400;}
.body3 {font-family: "Souvenir-Medium-Italic";
  font-size: 0.75rem;
  font-weight: 400;}
.body4 {font-family: "Souvenir-Medium-Italic";
  font-size: 1rem;
  font-weight: 400;}
.body5 {font-family: "Souvenir-Light";
  font-size: 0.875rem;
  font-weight: 400;}.title1_black {@extend .title1;  color: #FF4337;
  text-transform: uppercase;}
.title1_accentDark {@extend .title1;  color: #FF4337;
  text-transform: uppercase;}
.title3_accentGreen {@extend .title3;  color: #009B00;
  text-transform: uppercase;}
.title1_accentLight {@extend .title1;  color: #FFADAD;
  text-transform: uppercase;}
.title2_black {@extend .title2;  color: #FF4337;
  text-transform: uppercase;}
.title2_baseWhite {@extend .title2;  color: #FFFFFF;
  text-transform: uppercase;}
.title2_baseGray {@extend .title2;  color: #989898;
  text-transform: uppercase;}
.title2_accentDark {@extend .title2;  color: #FF4337;
  text-transform: uppercase;}
.title2_accentGreen {@extend .title2;  color: #009B00;
  text-transform: uppercase;}
.title2_accentLight {@extend .title2;  color: #FFADAD;
  text-transform: uppercase;}
.title3_black {@extend .title3;  color: #FF4337;
  text-transform: uppercase;}
.title3_accentDark {@extend .title3;  color: #FF4337;
  text-transform: uppercase;}
.title3_accentLight {@extend .title3;  color: #FFADAD;
  text-transform: uppercase;}
.title4_accentDark {@extend .title4;  color: #FF4337;
  text-transform: initial;}
.title4_accentLight {@extend .title4;  color: #FFADAD;
  text-transform: uppercase;}
.title4_black {@extend .title4;  color: #FF4337;
  text-transform: uppercase;}
.title4_accentGreen {@extend .title4;  color: #009B00;
  text-transform: uppercase;}
.title4_baseWhite {@extend .title4;  color: #FFFFFF;
  text-transform: uppercase;}
.title5_accentLight {@extend .title5;  color: #FFADAD;
  text-transform: uppercase;}
.title6_accentGreen {@extend .title6;  color: #009B00;
  text-transform: initial;}
.title7_accentGreen {@extend .title7;  color: #009B00;
  text-transform: initial;}
.subtitle1_black {@extend .subtitle1;  color: #FF4337;
  text-transform: uppercase;}
.subtitle1_accentGreen {@extend .subtitle1;  color: #009B00;
  text-transform: initial;}
.subtitle1_accentGreenLight {@extend .subtitle1;  color: #A8DCA8;
  text-transform: initial;}
.subtitle1_baseWhite {@extend .subtitle1;  color: #FFFFFF;
  text-transform: initial;}
.subtitle1_baseWarning {@extend .subtitle1;  color: #FF2A00;
  text-transform: initial;}
.subtitle2_baseWhite {@extend .subtitle2;  color: #FFFFFF;
  text-transform: initial;}
.subtitle1_accentDark {@extend .subtitle1;  @extend .base;  color: #FF4337;
  text-transform: uppercase;}
.subtitle1_baseGray {@extend .subtitle1;  @extend .base;  color: #989898;
  text-transform: uppercase;}
.subtitle3_accentDark {@extend .subtitle3;  @extend .base;  color: #FF4337;
  text-transform: uppercase;}
.subtitle8_accentDark {@extend .subtitle8;  @extend .base;  color: #FF4337;
  text-transform: uppercase;}
.subtitle3_accentGreen {@extend .subtitle3;  @extend .base;  color: #009B00;
  text-transform: uppercase;}
.subtitle4_accentDark {@extend .subtitle4;  color: #FF4337;
  text-transform: initial;}
.subtitle5_accentDark {@extend .subtitle5;  @extend .base;  color: #FF4337;
  text-transform: initial;}
.subtitle5_baseWhite {@extend .subtitle5;  color: #FFFFFF;
  text-transform: initial;}
.subtitle5_accentGreen {@extend .subtitle5;  @extend .base;  color: #009B00;
  text-transform: initial;}
.subtitle6_accentDark {@extend .subtitle6;  @extend .base;  color: #FF4337;
  text-transform: uppercase;}
.subtitle7_baseWhite {@extend .subtitle7;  @extend .base;  color: #FFFFFF;
  text-transform: uppercase;}
.subtitle7_accentDark {@extend .subtitle7;  @extend .base;  color: #FF4337;
  text-transform: uppercase;}
.subtitle8_baseWhite {@extend .subtitle8;  @extend .base;  color: #FFFFFF;
  text-transform: uppercase;}
.subtitle8_accentDarkLight {@extend .subtitle8;  @extend .base;  color: #FFADAD;
  text-transform: uppercase;}
.body1_black {@extend .body1;  color: #FF4337;
  text-transform: initial;}
.body1_baseWhite {@extend .body1;  color: #FFFFFF;
  text-transform: initial;}
.subtitle3_baseWhite {@extend .subtitle3;  color: #FFFFFF;
  text-transform: uppercase;}
.body1_baseGray {@extend .body1;  color: #989898;
  text-transform: initial;}
.body1_accentGreen {@extend .body1;  color: #009B00;
  text-transform: initial;}
.body1_baseWarning {@extend .body1;  color: #FF2A00;
  text-transform: initial;}
.body1_accentDark {@extend .body1;  color: #FF4337;
  text-transform: initial;}
.body2_black {@extend .body2;  color: #FF4337;
  text-transform: initial;}
.body2_accentDark {@extend .body2;  color: #FF4337;
  text-transform: initial;}
.body2_accentGreen {@extend .body2;  color: #009B00;
  text-transform: initial;}
.body2_baseGray {@extend .body2;  color: #989898;
  text-transform: initial;}
.body2_baseLink {@extend .body2;  color: #1173FF;
  text-transform: initial;}
.body3_accentDark {@extend .body3;  color: #FF4337;
  text-transform: initial;}
.body3_accentGreen {@extend .body3;  color: #009B00;
  text-transform: initial;}
.body4_baseWhite {@extend .body4;  color: #FFFFFF;
  text-transform: uppercase;}
.body4_accentGreen {@extend .body4;  color: #009B00;
  text-transform: uppercase;}
.body5_accentGreen {@extend .body5;  color: #009B00;
  text-transform: initial;}.buttonPrimaryStandard {
  &:active {
    @extend .title2_accentGreen;
  }
  
  @extend .title2_accentGreen;
@extend .baseAccentGreenBorderedCircle;
}
.buttonPrimaryStandardBordered {
  &:active {
    @extend .title2_baseWhite;
background-color: #FFADAD;
  }
  
  @extend .title2_accentGreen;
@extend .accentLightLightBordered;
}
.buttonPrimaryStandardOutlined {
  &:active {
    @extend .title2_baseWhite;
background-color: #FF4337;
  }
  &:disabled {
    @extend .title2_baseGray;
background-color: #989898;
  }
  @extend .title2_accentGreen;
@extend .baseBordered;
}
.buttonPrimaryClear {
  &:active {
    @extend .body2_black;
background-color: #FFADAD;
  }
  
  @extend .body2_black;
@extend .base;
}
.buttonAccentDarkClear {
  &:active {
    @extend .body2_accentDark;
  }
  
  @extend .body2_accentDark;
@extend .base;
}
.buttonAccentGreenClear {
  &:active {
    @extend .body2_accentGreen;
  }
  
  @extend .body2_accentGreen;
@extend .base;
}
.buttonPrimaryClearBordered {
  &:active {
    @extend .title2_baseWhite;
background-color: #FFADAD;
  }
  
  @extend .title2_black;
@extend .base;
}
.buttonPrimaryAccentLightBordered {
  &:active {
    @extend .title2_baseWhite;
background-color: #FF4337;
  }
  
  @extend .title2_black;
@extend .baseAccentBordered;
}
.buttonPrimaryAccentDark {
  &:active {
    @extend .title4_baseWhite;
  }
  
  @extend .title4_baseWhite;
@extend .accentDarkRounded;
}
.buttonSecondaryAccentDarkRounded {
  &:active {
    @extend .subtitle7_baseWhite;
  }
  
  @extend .subtitle7_baseWhite;
@extend .accentDarkRounded;
}
.buttonPrimaryAccentLight {
  &:active {
    @extend .body4_baseWhite;
  }
  
  @extend .body4_baseWhite;
@extend .accentLightRounded;
}
.buttonFooterAccentLight {
  &:active {
    @extend .title4_baseWhite;
  }
  
  @extend .title4_baseWhite;
@extend .accentLightRounded;
}
.buttonFooterAccentGreen {
  &:active {
    @extend .subtitle3_baseWhite;
  }
  
  @extend .subtitle3_baseWhite;
@extend .accentGreen;
}
.buttonNavStandard {
  &:active {
    @extend .title2_black;
background-color: #FFADAD;
  }
  
  @extend .title2_black;
@extend .baseAccentLightVeryHeavyBordered;
}
.buttonMenuNavStandard {
  &:active {
    @extend .title3_accentDark;
  }
  
  @extend .title3_accentLight;
@extend .base;
}
.buttonItemNavStandard {
  &:active {
    @extend .title3_accentDark;
  }
  
  @extend .title3_accentLight;
@extend .accentLightBordered;
}
.buttonSecondaryAccentDark {
  &:active {
    @extend .subtitle1_baseWhite;
background-color: #FF4337;
  }
  
  @extend .subtitle1_accentDark;
@extend .baseAccentDarkBorderedCircle;
}
.buttonSecondaryAccentGreen {
  &:active {
    @extend .subtitle1_accentGreen;
background-color: #009B00;
  }
  
  @extend .subtitle1_accentGreen;
@extend .baseAccentGreenBorderedCircle;
}
.buttonConfirm {
  &:active {
    @extend .subtitle8_baseWhite;
background-color: #009B00;
  }
  
  @extend .subtitle8_baseWhite;
@extend .accentGreenRounded;
}
.buttonCancel {
  &:active {
    @extend .subtitle8_baseWhite;
background-color: #009B00;
  }
  
  @extend .subtitle8_baseWhite;
@extend .accentGreenRounded;
}
.buttonTertiary {
  
  
  @extend .body1_black;
}
.buttonTertiaryBordered {
  
  
  @extend .body2_black;
@extend .baseBordered;
}
.buttonTertiaryAccentDark {
  
  
  @extend .body2_accentDark;
}
.buttonTertiaryLink {
  
  
  @extend .body2_baseLink;
}
.buttonTertiaryCircle {
  &:active {
    @extend .subtitle2_baseWhite;
  }
  
  @extend .subtitle2_baseWhite;
@extend .accentLightCircle;
}
.buttonTertiaryRadio {
  &:active {
    @extend .body1_baseWhite;
background-color: #FF4337;
  }
  
  @extend .body1_accentDark;
@extend .baseAccentDarkBorderedRound;
}
.buttonTertiaryRadioCircle {
  &:active {
    @extend .body1_baseWhite;
background-color: #009B00;
  }
  
  @extend .body1_accentGreen;
@extend .baseAccentGreenBorderedCircle;
}.primary {
@extend .body1_accentGreen;
 &::placeholder {
    @extend .body2_accentGreen;
  }
}
.secondary {
@extend .body2_accentGreen;
 &::placeholder {
    @extend .body2_accentGreen;
  }
}
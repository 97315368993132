$client: tacombi;
@import "styles/config";
@import "client";
.rc-tabs-bar {
  display: flex;
}

.rc-tabs-bar > div:first-child {
  display: flex;
  flex-grow: 1;
}

.rc-tabs-top {
  border-bottom: none;
}

.rc-tabs-top .rc-tabs-ink-bar {
  height: 2px;
}

.rc-tabs-top .rc-tabs-tab {
  flex-grow: 1;
  margin-right: 0px;
  flex-basis: 0;
}

.rc-tabs-tab {
  text-align: center;
}

.rc-tabs-tab-active.rc-tabs-tab:only-child {
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

/* .rc-tabs-tab */

.rc-tabs-tab-nofocus {
  outline: none;
}